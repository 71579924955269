import React, { Component } from "react";
// import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { LayoutNormalizer } from "@blablacar/ui-library/build/layout/layoutNormalizer";
import { BaseSection } from "@blablacar/ui-library/build/layout/section/baseSection";
import { Itinerary } from "@blablacar/ui-library/build/itinerary";
import { ContentDivider } from "@blablacar/ui-library/build/divider/contentDivider";
import { ItemChoice } from "@blablacar/ui-library/build/itemChoice";
import { Loader } from '@blablacar/ui-library/build/loader'
import { Avatar } from '@blablacar/ui-library/build/avatar';
import TripService from "../services/trip.service";
import AuthService from "../services/auth.service";
import OrderService from "../services/order.service";
// import 'react-google-places-autocomplete/dist/index.min.css';

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

export default class Payment extends Component {
  constructor(props) {
    super(props);

    this.makeOrder = this.makeOrder.bind(this);

    this.state = {
        trip_id: this.props.location.state.trip_id,
        trip: this.props.location.state.trip,
        departure: this.props.location.state.departure,
        destination: this.props.location.state.destination,
        vehicle: this.props.location.state.vehicle,
        driver: this.props.location.state.driver,
        seats: this.props.location.state.seats || 0,
        price_id: this.props.location.state.price || null,
        rider: AuthService.getCurrentUser().user.id,
        status: 0,
        priceList:[],
        order: {},
        errors: []
    }
  }

  componentDidMount(){
    TripService.priceList().then(response => {
      this.setState({priceList: response.map(item => {
        return {label: `${item.price_type.name }(R${item.value})`, value: item.id}
      })});
    });
  }

  makeOrder(e){

    let errors = []

    if(this.state.seats === 0 || this.state.seats === null ){
      errors.push('Seats')
    }

    if(this.state.price_id === undefined || this.state.price_id === null ){
      errors.push('Price')
    }

    if(errors.length > 0){
      this.setState({errors: errors})
      return
    }
    else{
      OrderService.create(this.state.rider, this.state.trip_id, this.state.status, this.state.price_id, this.state.seats).then(response => {
        this.setState({order: response})
        document.getElementById("tab-multi-summary").disabled = true;
        document.getElementById("tab-multi-payment").checked = true;
      })
    }
  }

  render() {
    return (

      <div className="container">
         {this.state.loading ? <Loader /> :
         <div>
          <LayoutNormalizer useLegacyNormalization={false} />



              <BaseSection noHorizontalSpacing>


                <div className="container mx-auto">
                  <div className="w-full">
                        <div className="shadow-md">


                        <div className="tab w-full overflow-hidden">
                              <input className="absolute opacity-0" id="tab-multi-summary" type="radio" name="tabs" checked />
                              <label className="block bg-white p-4 leading-normal cursor-pointer" htmlFor="tab-multi-date-time">Trip Summary</label>
                              <div className="tab-content overflow-auto border-l-2 bg-gray-100 border-green-300 leading-normal">


                                <BaseSection  noHorizontalSpacing>
                                  <div className="pl-3 pr-3">

                                    <Itinerary places={[{
                                          mainLabel: this.state.departure.name,
                                          subLabel: this.state.departure.province,
                                        // href: '#'
                                        }, {
                                          mainLabel: this.state.destination.name,
                                          subLabel: this.state.destination.province,
                                        // href: '#'
                                        }]} />
                                  </div>
                                </BaseSection>

                                <BaseSection noHorizontalSpacing>
                                  <ItemChoice label={"Date: " + this.state.trip.date}  href="#" />
                                  <ItemChoice label={"Time: " + this.state.trip.time.split('T')[1].substring(0, 5) }  href="#" />

                                  <ItemChoice label={this.state.driver.first_name + ' ' + this.state.driver.last_name} rightAddon={<Avatar  image="https://cdn.onlinewebfonts.com/svg/img_558691.png" />} href="#" />

                                  <ItemChoice label={this.state.vehicle.make + ' ' + this.state.vehicle.model + ' ('+ this.state.vehicle.color +')'} rightAddon={<Avatar image="https://ipsoft.co.za/wp-content/uploads/2017/03/IPSoft-Industry_Automotive.png" />} href="#" />

                                  <div className="form-group pl-3 pr-3">
                                    <p style={{ color: 'red' }}>{this.state.errors && this.state.errors.join(', ')} {this.state.errors.length ? ' field(s) are required.' : ''}</p>

                                    <label htmlFor="seats">Seats Required *</label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      name="seats"
                                      min="1"
                                      max={this.state.trip.seats}
                                      value={this.state.seats}
                                      onChange={(e) => {this.setState({seats: e.target.value})}}
                                      validations={[required]}
                                    />
                                  </div>


                                  <div className="form-group pl-3 pr-3">
                                    <label htmlFor="price_ids">Payment type *</label>
                                    <select name='price_id' validations={[required]}
                                      className="form-control"
                                      value={this.state.price_id}
                                      onChange={(e) => {this.setState({price_id: e.target.value});}}
                                    >

                                      <option key="" value="">Select Price</option>
                                      {this.state.priceList.map((item, i) => {
                                        return <option value={item.value} key={i}>{item.label}</option>
                                      })}
                                  </select>
                                </div>

                                  </BaseSection>
                                  <br/>
                                  <ContentDivider />
                                  <div className="payment m-2">
                                    <ItemChoice label="Book your seat(s)" style={{textDecoration: 'none', color: '#2DBEFF'}} onClick={this.makeOrder} />
                                  </div>
                                </div>

                              </div>
                              <div className="tab w-full overflow-hidden">
                                <input className="absolute opacity-0" id="tab-multi-payment" type="radio" name="tabs" disabled/>
                                <label className="block bg-white p-4 leading-normal cursor-pointer" htmlFor="tab-multi-payment">Deposit Payment</label>
                                <div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-green-300 leading-normal">
                                  <div className="m-2">
                                    <p className="p-2">Send R{50 * this.state.seats} via Capitec to cellphone number/ FNB Ewallet 0699042846, reference LS{this.state.order.reference_number}. This booking is available for confirmation for only an hour!</p>
                                  </div>
                                  <div className="m-2">
                                    <p className="p-2">Completed payment? <a className="text-green-600 underline text-small" href={`/trips/${this.state.trip_id}`}>Contact</a> driver.</p>
                                  </div>
                                  <ContentDivider />
                                  <div className="m-2">
                                    <p className="p-2">Or Continue with Online payment</p>
                                    <a href={`https://www.payfast.co.za/eng/process?cmd=_paynow&receiver=11799876&item_name=${this.state.order.reference_number}&item_description=Long+distance+ride+R${50 * this.state.seats}+deposit+per+seat&amount=${50 * this.state.seats}&return_url=https://liftsearch.co.za/success_payment?order_id=${this.state.order.id}&cancel_url=https://liftsearch.co.za/cancel_payment`}><img  className="p-2" src="https://www.payfast.co.za/images/buttons/dark-small-paynow.png" width="165" height="36" alt="Pay" title="Pay Now with PayFast" target="_blank" /></a>
                                  </div>
                                  <div class="flex gap-4 bg-red-100 p-4 rounded-md">
                                      <div class="w-max">
                                          <div class="h-10 w-10 flex rounded-full bg-gradient-to-b from-red-100 to-orange-300 text-orange-700">
                                            <span class="material-icons material-icons-outlined m-auto" style={{fontSize: "20px"}}>gpp_bad</span>
                                          </div>
                                      </div>
                                      <div class="space-y-1 text-sm">
                                        <h6 class="font-medium text-orange-900">Why we collect R50 deposit?</h6>
                                        <p class="text-orange-700 leading-tight">Liftsearch's core services are safety and reliability for drivers and passengers. The R50 deposit is your upfront commitment that the driver will find you when he comes to pick you up. You only pay the remaining balance to the driver.</p>
                                      </div>
                                  </div>
                                  <ContentDivider />
                                  <div className="m-2">
                                    <p className="p-2"><a className="text-green-600 underline text-small" href={`/subscriptions/rider`}>Subscribe</a> to Liftsearch and make as many bookings and gain access to the driver's contact details instantly without making the R50 deposit for the whole year/month.</p>
                                  </div>
                                </div>
                              </div>
                          </div>
                      </div>
                      </div>
              </BaseSection>
            </div>
          }
      </div>

  );
  }
}