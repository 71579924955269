import React, { Component } from "react";
import { LayoutNormalizer } from "@blablacar/ui-library/build/layout/layoutNormalizer";
import { BaseSection } from "@blablacar/ui-library/build/layout/section/baseSection";
import { ContentDivider } from "@blablacar/ui-library/build/divider/contentDivider";
import { Loader } from '@blablacar/ui-library/build/loader'


export default class MissedPayment extends Component {
  constructor(props) {
    super(props);

    this.state = {
        trip: this.props.location.state.trip,
        order: this.props.location.state.order,
        errors: []
    }
  }

  componentDidMount(){
  
  }


  render() {
    return (

      <div className="container">
         {this.state.loading ? <Loader /> :
         <div>
          <LayoutNormalizer useLegacyNormalization={false} />



              <BaseSection noHorizontalSpacing>


                <div className="container mx-auto">
                  <div className="w-full">
                        <div className="shadow-md">
                              <div className="w-full overflow-hidden">
                                <label className="block bg-white p-4 leading-normal cursor-pointer" htmlFor="tab-multi-payment">Deposit Payment</label>
                                <div className="overflow-hidden border-l-2 bg-gray-100 border-green-300 leading-normal">
                                  <div className="m-2">
                                    <p className="p-2">Send R{50 * this.state.order.seats} via Capitec to cellphone number/ FNB Ewallet 0699042846, reference LS{this.state.order.reference_number}. This booking is available for confirmation for only an hour!</p>
                                  </div>
                                  <div className="m-2">
                                    <p className="p-2">Completed payment? <a className="text-green-600 underline text-small" href={`/trips/${this.state.trip.id}`}>Contact</a> driver.</p>
                                  </div>
                                  <ContentDivider />
                                  <div className="m-2">
                                    <p className="p-2">Or Continue with Online payment</p>
                                    <a href={`https://www.payfast.co.za/eng/process?cmd=_paynow&receiver=11799876&item_name=${this.state.order.reference_number}&item_description=Long+distance+ride+R${50 * this.state.order.seats}+deposit+per+seat&amount=${50 * this.state.order.seats}&return_url=https://liftsearch.co.za/success_payment?order_id=${this.state.order.id}&cancel_url=https://liftsearch.co.za/cancel_payment`}><img  className="p-2" src="https://www.payfast.co.za/images/buttons/dark-small-paynow.png" width="165" height="36" alt="Pay" title="Pay Now with PayFast" target="_blank" /></a>
                                  </div>
                                  <div class="flex gap-4 bg-red-100 p-4 rounded-md">
                                      <div class="w-max">
                                          <div class="h-10 w-10 flex rounded-full bg-gradient-to-b from-red-100 to-orange-300 text-orange-700">
                                            <span class="material-icons material-icons-outlined m-auto" style={{fontSize: "20px"}}>gpp_bad</span>
                                          </div>
                                      </div>
                                      <div class="space-y-1 text-sm">
                                        <h6 class="font-medium text-orange-900">Why we collect R50 deposit?</h6>
                                        <p class="text-orange-700 leading-tight">Liftsearch's core services are safety and reliability for drivers and passengers. The R50 deposit is your upfront commitment that the driver will find you when he comes to pick you up. You only pay the remaining balance to the driver.</p>
                                      </div>
                                  </div>
                                  <ContentDivider />
                                  <div className="m-2">
                                    <p className="p-2"><a className="text-green-600 underline text-small" href={`/subscriptions/rider`}>Subscribe</a> to Liftsearch and make as many bookings and gain access to the driver's contact details instantly without making the R50 deposit for the whole year/month.</p>
                                  </div>
                                </div>
                              </div>
                          </div>
                      </div>
                      </div>
              </BaseSection>
            </div>
          }
      </div>

  );
  }
}